<template>
  <!-- Master Card -->
  <div>
    <div class="animated-wallet-border-box-glow"></div>
    <div class="animated-wallet-border-box"></div>

    <a-card
      class="card-credit"
      style="
        background-color: rgb(113 77 0) !important;
        border: 1px solid #ffae0098 !important;
      "
    >
      <!-- <a-card class="card-credit header-solid h-full"> -->

      <div style="margin-left: 14px">
        <div class="mr-30">
          <p>กระเป๋าเงิน</p>
        </div>
        <h2 class="m-0"><Balance /></h2>

        <div class="card-footer">
          <!-- <div class="mr-30">
          <p>Username</p>
          <h6>{{ getUsername }}</h6>
        </div> -->
          <!-- <div class="mr-30">
          <p>เวลาปัจจุบัน</p>
          <h6>{{ timestamp }}</h6>
        </div> -->
          <div class="card-footer-col col-logo ml-auto">
            <p>Username</p>
            <h6>{{ getUsername }}</h6>
          </div>
        </div>
      </div>
    </a-card>
  </div>
  <!-- / Master Card -->
</template>

<script>
import Balance from "@/views/components/wallet/Balance.vue";

export default {
  components: {
    Balance,
  },
  computed: {
    getUsername: function () {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    // displayTime: function () {
    //   return setInterval(this.refreshTime, 1000);
    // },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  data() {
    return { timestamp: "loading..." };
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        (today.getHours().toString().length != 2 ? "0" : "") +
        today.getHours() +
        ":" +
        (today.getMinutes().toString().length != 2 ? "0" : "") +
        today.getMinutes() +
        ":" +
        (today.getSeconds().toString().length != 2 ? "0" : "") +
        today.getSeconds();
      const dateTime = time + " น.";
      this.timestamp = dateTime;
    },
  },
};
</script>
<style lang="scss">
.animated-wallet-border-box,
.animated-wallet-border-box-glow {
  height: 104%;
  width: 99%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 12px;
  top: -4px;
  left: 4px;
}

.animated-wallet-border-box-glow {
  overflow: hidden;
  /* Glow Blur */
  filter: blur(20px);
}

.animated-wallet-border-box:before,
.animated-wallet-border-box-glow:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #ffae00,
    rgba(0, 0, 0, 0) 25%
  );
  /* change speed here */
  animation: rotate 4s linear infinite;
}

.animated-wallet-border-box:after {
  content: "";
  position: absolute;
  z-index: -2;
  /* border width */
  left: 5px;
  top: 5px;
  /* double the px from the border width left */
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  /*bg color*/
  /*box border radius*/
  border-radius: 7px;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@media (max-width: 449px) {
  // .header_color {
  //   background-color: #ffae0051 !important;
  //   border: 1px solid #ffae0098;
  // }
  .gold_color {
    background-color: #ffae0051 !important;
    border: 1px solid #ffae0098 !important;
  }
  .card-credit {
    width: 100%;
    height: 120px;

    p {
      font-size: 12px;
      margin: 0;
    }
    h2 {
      font-size: 24px;
      margin: 0;
    }
    .card-footer-col {
      p {
        font-size: 10px;
        margin: 0;
      }
      h6 {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
</style>
