<template>
  <!-- <div> -->
  <!-- <a-card :bordered="false">-->

  <div id="charts" style="padding: 0 !important">
    <a-row type="flex" :gutter="24">
      <a-col :span="12">
        <p>กราฟรวม {{ gameType }}</p>
        <a-col :span="24" :xl="24" class="p-0" v-if="gameType != 'ROULETTE'">
          <a-switch
            v-if="!resize"
            :class="['mr-20', toggle ? 'ant-switch-checked-all' : '']"
            :value="toggle"
            @change="onChange"
            :checked="toggle"
          />
          <a-switch
            v-if="!resize"
            :class="['mr-20', toggle1 ? 'ant-switch-checked-player' : '']"
            :value="toggle1"
            @change="onChange1"
            :checked="toggle1"
          />
          <a-switch
            v-if="!resize"
            class=""
            :value="toggle2"
            @change="onChange2"
            :checked="toggle2"
          />
        </a-col>
      </a-col>
    </a-row>

    <apexchart
      ref="realtimeChart"
      type="area"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
    <!-- <ResultBoard
      ref="result_board"
      :gameType="gameType"
      v-if="gameType != 'ROULETTE' && resize == false"
    />
    <ResultBoardRoulette
      ref="result_board"
      :gameType="gameType"
      v-if="gameType == 'ROULETTE' && resize == false"
    /> -->
  </div>
  <!-- </a-card>
  </div> -->
</template>

<script>
import ResultBoard from "./ResultBoard.vue";
import ResultBoardRoulette from "./ResultBoardRoulette.vue";

export default {
  name: "BrushCharts",
  components: {
    ResultBoard,
    ResultBoardRoulette,
  },

  props: {
    height: {
      type: Number,
      default: 180,
    },
    gameType: {
      type: String,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.gameType === "DRAGON_TIGER") {
      this.series[1].name = "TIGER";
      this.series[2].name = "DRAGON";
    }

    this.getGameHistory(this.gameType);
  },
  data: function () {
    return {
      series: [
        {
          name: "ALL",
          data: [],
        },
        {
          name: "PLAYER",
          data: [],
        },
        {
          name: "BANKER",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#ffbb44", "#3358ff", "#aa1100"],

        stroke: {
          dashArray: 4,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 3,
          dashArray: 0,
        },

        xaxis: {
          type: "category",
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        yaxis: {
          show: true,
          showAlways: true,

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#8C8C8C1a",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        // tooltip: {
        //   x: {
        //     format: "dd/MM/yy HH:mm",
        //   },
        //   y: {
        //     formatter: undefined,
        //     title: {
        //       formatter: (seriesName) => seriesName,
        //     },
        //   },
        // },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            //console.log("series", w.config.series[0].name);
            let PLAYER = "";
            let DEFUALT = "";
            let BANKER = "";
            if (w.config.series[0].data.length != 0) {
              DEFUALT =
                "<li class='ant-switch-t1'> <div class='font-black'>" +
                w.config.series[0].data[dataPointIndex].x +
                "\n" +
                w.config.series[0].name +
                ": " +
                series[0][dataPointIndex] +
                "</div></li>";
            }

            if (w.config.series[1].data.length != 0) {
              PLAYER =
                "<li class='ant-switch-t2'> <div class='font-black'>" +
                w.config.series[1].data[dataPointIndex].x +
                "\n" +
                w.config.series[1].name +
                ": " +
                series[1][dataPointIndex] +
                "</div></li>";
            }
            if (w.config.series[2].data.length != 0) {
              BANKER =
                "<li class='ant-switch-t3'> <div class='font-black'>" +
                w.config.series[2].data[dataPointIndex].x +
                "\n" +
                w.config.series[2].name +
                ": " +
                series[2][dataPointIndex] +
                "</div></li>";
            }

            return DEFUALT + PLAYER + BANKER;
          },
        },
      },
      chartPointer: 0,
      chartPointer1: 0,
      chartPointer2: 0,
      toggle: true,
      toggle1: true,
      toggle2: true,
    };
  },

  methods: {
    onChange(checked) {
      this.toggle = checked;
      this.getGameHistory(this.gameType);
    },
    onChange1(checked) {
      this.toggle1 = checked;
      this.getGameHistory(this.gameType);
    },
    onChange2(checked) {
      this.toggle2 = checked;
      this.getGameHistory(this.gameType);
    },
    generateDayWiseTimeSeries: function (baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
      }

      return series;
    },
    setResultBoard(val) {
      this.$refs.result_board.setPath(val);
    },
    // addResultBoard(val) {
    //   this.$refs.result_board.addResult(
    //     val.roundStartTime != undefined
    //       ? { ...val, createdAt: val.roundStartTime }
    //       : val
    //   );
    // },

    getGameHistory(val) {
      this.chartPointer = 0;
      this.chartPointer1 = 0;
      this.chartPointer2 = 0;
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/game/${val}/history?limit=50&isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.getChart(data);
        })
        .catch((err) => {
          return err;
        });
    },
    getChart(data) {
      this.chartOptions.xaxis.categories = [];
      this.chartOptions.xaxis.categories.push("");
      this.series[0].data = [];
      this.series[1].data = [];
      this.series[2].data = [];

      for (let i = data.length - 1; i >= 0; i--) {
        try {
          if (data[i].isWin !== undefined) {
            this.addChart(data[i], "PREDICTION");
            // console.log(data[i]);
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.updateSeriesLine();
    },

    addChart(val, tag) {
      let label =
        "" +
        "ฝั่งที่เล่น " +
        (this.gameType != "ROULETTE"
          ? val.prediction
          : val.prediction.playerType.toString()) +
        "<br>" +
        "ผลที่ออก " +
        (val.result != null ? val.result.winner : "เสมอ") +
        "<br>" +
        "ผลทำนาย " +
        (val.isWin === null ? "เสมอ" : val.isWin ? "ชนะ" : "แพ้") +
        "<br>เวลา " +
        new Date(
          tag === "PREDICTION" ? val.createdAt : val.roundStartTime
        ).toLocaleString("th-TH", {
          timeZone: "Asia/Bangkok",
        });

      let point = 0;
      if (val.isWin === null) {
        point = 0;
      } else if (val.isWin) {
        point = 1;
      } else {
        point = -1;
      }

      this.chartPointer += point;

      if (val.prediction === "PLAYER" || val.prediction === "TIGER") {
        this.chartPointer1 += point;
      } else if (val.prediction === "BANKER" || val.prediction === "DRAGON") {
        this.chartPointer2 += point;
      }

      if (this.toggle === true) {
        this.series[0].data.push({
          x: label,
          y: this.chartPointer,
        });
      }
      if (this.toggle1 === true) {
        this.series[1].data.push({
          x: label,
          y: this.chartPointer1,
        });
      }
      if (this.toggle2 === true) {
        this.series[2].data.push({
          x: label,
          y: this.chartPointer2,
        });
      }

      this.chartOptions.xaxis.categories.push(label);
    },

    updateSeriesLine() {
      const series = [
        {
          name: this.series[0].name,
          data: this.series[0].data,
        },
      ];

      if (this.gameType != "ROULETTE") {
        series.push({
          name: this.series[1].name,
          data: this.series[1].data,
        });
        series.push({
          name: this.series[2].name,
          data: this.series[2].data,
        });
      }

      this.$refs.realtimeChart.updateSeries(series, false, true);
    },
  },
};
</script>

<style scoped>
#charts {
  /* background-image: linear-gradient(to right, #1890ff, #1890ff, #1890ff); */
  border-radius: 8px;
}
.ant-switch-checked-all {
  background-color: #ffbb44;
}
.ant-switch-checked-player {
  background-color: #3358ff;
}
</style>
