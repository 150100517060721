<!-- 
  This is the billing page, it uses the dashboard layout in: 
  "./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <Loading :loading="false" />
    <!-- <button @click="test">test</button> -->
    <a-row type="flex" :gutter="24">
      <!-- Billing Info Column -->

      <a-col :span="24" :md="24">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :xl="24" class="mb-24">
            <!-- Master Card -->
            <Wallet></Wallet>
            <!-- / Master Card -->
          </a-col>

          <a-col :span="24" :md="24" class="mb-24">
            <a-col
              v-for="(data, i) in listServer"
              class="col-content p-0 pr-10"
              :key="i"
              :span="24"
              :xl="8"
              :xs="12"
              @click="next(data)"
            >
              <GameButton :title="data.gameType" :data="data" class="mb-10" />
            </a-col>

            <!-- / Invoices Card -->
          </a-col>

          <!-- / Invoices Column -->
        </a-row>
      </a-col>
      <!-- / Billing Info Column -->
    </a-row>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import Wallet from "./components/wallet/Wallet";
import CardInfo from "../components/Cards/CardInfo";
import Loading from "./components/Loading.vue";
import Chart from "./components/chart/Chart.vue";
import gameList from "@/views/json/gameList.json";
import GameButton from "./components/GameButton.vue";
import ChartRoulette from "./components/chart/ChartRoulette.vue";

export default {
  components: {
    Wallet,
    CardInfo,
    Loading,
    Chart,
    PulseLoader,
    GameButton,
    ChartRoulette,
  },

  mounted() {
    //this.games = gameList;
    this.unSubAll();
    console.log("unsub");
    this.getListServer();
  },
  data() {
    return {
      games: [],
      listServer: [],
    };
  },
  methods: {
    next(val) {
      this.$router.push({
        path: `/game/${val.gameType}-${val.tableId}`,
      });
      //   .catch(() => {});
    },
    getListServer() {
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/server-bot`)
        .then(({ data }) => {
          console.log(data);
          this.listServer = data.list;
          this.unSubAll();
        })
        .catch((err) => {
          return err;
        });
    },
    unSubAll() {
      for (var i = 0; i < this.listServer.length; i++) {
        this.sockets.unsubscribe(
          `game-${this.listServer[i].gameType}-${this.listServer[i].tableId}-live`
        );
      }
    },
    mockSwitch() {
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/api/me/mock/switch`, {
          isMock: !this.$store.getters.getMock,
        })
        .then(({ data }) => {
          this.$store
            .dispatch("setMock", !this.$store.getters.getMock)
            .then(() => {
              window.location.reload();
            });
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
