<template>
  <!-- <div> -->
  <!-- <a-card :bordered="false">-->

  <div id="charts" style="padding: 0 !important">
    <a-col>
      <p>กราฟรวม {{ gameType }}</p>
      <a-col :span="24" :xl="24" class="py-2">
        <a-switch
          v-if="!resize"
          :class="['mr-20', toggle ? 'ant-switch-checked-t1' : '']"
          :value="toggle"
          @change="onChange"
          :checked="toggle"
        />
        <a-switch
          v-if="!resize"
          :class="['mr-20', toggle1 ? 'ant-switch-checked-t2' : '']"
          :value="toggle1"
          @change="onChange1"
          :checked="toggle1"
        />
        <a-switch
          v-if="!resize"
          :class="['mr-20', toggle3 ? 'ant-switch-checked-t3' : '']"
          :value="toggle2"
          @change="onChange2"
          :checked="toggle2"
        />
        <a-switch
          v-if="!resize"
          :class="['mr-20', toggle3 ? 'ant-switch-checked-t4' : '']"
          :value="toggle3"
          @change="onChange3"
          :checked="toggle3"
        />
        <a-switch
          v-if="!resize"
          :class="['mr-20', toggle3 ? 'ant-switch-checked-t5' : '']"
          :value="toggle4"
          @change="onChange4"
          :checked="toggle4"
        />
      </a-col>
    </a-col>

    <apexchart
      ref="realtimeChart"
      type="area"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
    <!-- <ResultBoard
      ref="result_board"
      :gameType="gameType"
      v-if="gameType != 'ROULETTE' && resize == false"
    />
    <ResultBoardRoulette
      ref="result_board"
      :gameType="gameType"
      v-if="gameType == 'ROULETTE' && resize == false"
    /> -->
  </div>
  <!-- </a-card>
  </div> -->
</template>

<script>
import ResultBoard from "./ResultBoard.vue";
import ResultBoardRoulette from "./ResultBoardRoulette.vue";

export default {
  name: "BrushCharts",
  components: {
    ResultBoard,
    ResultBoardRoulette,
  },

  props: {
    height: {
      type: Number,
      default: 180,
    },
    gameType: {
      type: String,
    },
    resize: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getGameHistory(this.gameType);
  },
  data: function () {
    return {
      series: [
        {
          name: "OddEven",
          data: [],
        },
        {
          name: "OneZone",
          data: [],
        },
        {
          name: "RedBlack",
          data: [],
        },
        {
          name: "SmallBig",
          data: [],
        },
        {
          name: "TwoZone",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#ffbb44", "#3358ff", "#aa1100", "#11bb44", "#225544"],

        stroke: {
          dashArray: 4,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 3,
          dashArray: 0,
        },

        xaxis: {
          type: "category",
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        yaxis: {
          show: true,
          showAlways: true,

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#8C8C8C",
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#8C8C8C1a",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        // tooltip: {
        //   x: {
        //     format: "dd/MM/yy HH:mm",
        //   },
        // },

        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let RB = "";
            let EO = "";
            let SB = "";
            let TWOZONE = "";
            let ONEZONE = "";
            if (w.config.series[0].data.length != 0) {
              RB =
                "<div style='padding:2px;'><li class='ant-switch-t1' > <div class='font-black'>" +
                w.config.series[0].data[dataPointIndex].x +
                "<br/>" +
                w.config.series[0].name +
                ": " +
                series[0][dataPointIndex] +
                "</div></li>";
            }

            if (w.config.series[1].data.length != 0) {
              EO =
                "<li class='ant-switch-t2'> <div class='font-black'>" +
                w.config.series[1].data[dataPointIndex].x +
                "<br/>" +
                w.config.series[1].name +
                ": " +
                series[1][dataPointIndex] +
                "</div></li>";
            }
            if (w.config.series[2].data.length != 0) {
              SB =
                "<li class='ant-switch-t3'> <div class='font-black'>" +
                w.config.series[2].data[dataPointIndex].x +
                "<br/>" +
                w.config.series[2].name +
                ": " +
                series[2][dataPointIndex] +
                "</div></li>";
            }
            if (w.config.series[3].data.length != 0) {
              TWOZONE =
                "<li class='ant-switch-t4'> <div class='font-black'>" +
                w.config.series[3].data[dataPointIndex].x +
                "<br/>" +
                w.config.series[3].name +
                ": " +
                series[3][dataPointIndex] +
                "</div></li>";
            }
            if (w.config.series[4].data.length != 0) {
              ONEZONE =
                "<li class='ant-switch-t5'> <div class='font-black'>" +
                w.config.series[4].data[dataPointIndex].x +
                "<br/>" +
                w.config.series[4].name +
                ": " +
                series[4][dataPointIndex] +
                "</div></li></div>";
            }

            return RB + EO + SB + TWOZONE + ONEZONE;
          },
        },
      },
      chartPointer: 0,
      chartPointer1: 0,
      chartPointer2: 0,
      chartPointer3: 0,
      chartPointer4: 0,
      toggle: true,
      toggle1: true,
      toggle2: true,
      toggle3: true,
      toggle4: true,
    };
  },

  methods: {
    onChange(checked) {
      this.toggle = checked;
      this.getGameHistory(this.gameType);
    },
    onChange1(checked) {
      this.toggle1 = checked;
      this.getGameHistory(this.gameType);
    },
    onChange2(checked) {
      this.toggle2 = checked;
      this.getGameHistory(this.gameType);
    },
    onChange3(checked) {
      this.toggle3 = checked;
      this.getGameHistory(this.gameType);
    },
    onChange4(checked) {
      this.toggle4 = checked;
      this.getGameHistory(this.gameType);
    },

    generateDayWiseTimeSeries: function (baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
      }

      return series;
    },
    setResultBoard(val) {
      this.$refs.result_board.setPath(val);
    },
    // addResultBoard(val) {
    //   this.$refs.result_board.addResult(
    //     val.roundStartTime != undefined
    //       ? { ...val, createdAt: val.roundStartTime }
    //       : val
    //   );
    // },

    getGameHistory(val) {
      this.chartPointer = 0;
      this.chartPointer1 = 0;
      this.chartPointer2 = 0;
      this.chartPointer3 = 0;
      this.chartPointer4 = 0;
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/game/${val}/history?limit=50&isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.getChart(data);
        })
        .catch((err) => {
          return err;
        });
    },
    getChart(data) {
      this.chartOptions.xaxis.categories = [];
      this.chartOptions.xaxis.categories.push("");
      this.series[0].data = [];
      this.series[1].data = [];
      this.series[2].data = [];
      this.series[3].data = [];
      this.series[4].data = [];

      for (let i = data.length - 1; i >= 0; i--) {
        try {
          // console.log(data[i].additionalResult);

          this.addChart(data[i], "PREDICTION");
        } catch (error) {
          console.log(error);
        }
      }
      this.updateSeriesLine();
    },

    addChart(val, tag) {
      let label =
        "ฝั่งที่เล่น " +
        val.prediction.playerType.toString() +
        "<br>" +
        "ผลที่ออก " +
        (val.result != null ? val.result.winner : "เสมอ") +
        "<br>" +
        new Date(
          tag === "PREDICTION" ? val.createdAt : val.roundStartTime
        ).toLocaleString("th-TH", {
          timeZone: "Asia/Bangkok",
        });

      let point1 = 0;
      if (val.additionalResult === null) {
        point1 = 0;
      } else if (val.additionalResult.oddEven) {
        point1 = 1;
      } else {
        point1 = -1;
      }

      let point2 = 0;
      if (val.additionalResult === null) {
        point2 = 0;
      } else if (val.additionalResult.oneZone) {
        point2 = 1;
      } else {
        point2 = -1;
      }

      let point3 = 0;
      if (val.additionalResult === null) {
        point3 = 0;
      } else if (val.additionalResult.redBlack) {
        point3 = 1;
      } else {
        point3 = -1;
      }

      let point4 = 0;
      if (val.additionalResult === null) {
        point4 = 0;
      } else if (val.additionalResult.smallBig) {
        point4 = 1;
      } else {
        point4 = -1;
      }

      let point5 = 0;
      if (val.additionalResult === null) {
        point5 = 0;
      } else if (val.additionalResult.twoZone) {
        point5 = 1;
      } else {
        point5 = -1;
      }

      this.chartPointer += point1;
      this.chartPointer1 += point2;
      this.chartPointer2 += point3;
      this.chartPointer3 += point4;
      this.chartPointer4 += point5;

      if (this.toggle === true) {
        this.series[0].data.push({
          x: label,
          y: this.chartPointer,
        });
      }
      if (this.toggle1 === true) {
        this.series[1].data.push({
          x: label,
          y: this.chartPointer1,
        });
      }
      if (this.toggle2 === true) {
        this.series[2].data.push({
          x: label,
          y: this.chartPointer2,
        });
      }
      if (this.toggle3 === true) {
        this.series[3].data.push({
          x: label,
          y: this.chartPointer3,
        });
      }
      if (this.toggle4 === true) {
        this.series[4].data.push({
          x: label,
          y: this.chartPointer4,
        });
      }

      this.chartOptions.xaxis.categories.push(label);
    },

    updateSeriesLine() {
      const series = [
        {
          name: this.series[0].name,
          data: this.series[0].data,
        },
        {
          name: this.series[1].name,
          data: this.series[1].data,
        },
        {
          name: this.series[2].name,
          data: this.series[2].data,
        },
        {
          name: this.series[3].name,
          data: this.series[3].data,
        },
        {
          name: this.series[4].name,
          data: this.series[4].data,
        },
      ];

      // series.push({
      //   name: this.series[1].name,
      //   data: this.series[1].data,
      // });
      // series.push({
      //   name: this.series[2].name,
      //   data: this.series[2].data,
      // });
      // series.push({
      //   name: this.series[3].name,
      //   data: this.series[3].data,
      // });
      // series.push({
      //   name: this.series[4].name,
      //   data: this.series[4].data,
      // });

      this.$refs.realtimeChart.updateSeries(series, false, true);
    },
  },
};
</script>

<style>
#charts {
  /* background-image: linear-gradient(to right, #1890ff, #1890ff, #1890ff); */
  border-radius: 8px;
}
.ant-switch-checked-t1 {
  background-color: #ffbb44;
  margin: 4px;
}
.ant-switch-checked-t2 {
  background-color: #3358ff;
  margin: 4px;
}
.ant-switch-checked-t3 {
  background-color: #aa1100;
  margin: 4px;
}
.ant-switch-checked-t4 {
  background-color: #11bb44;
  margin: 4px;
}
.ant-switch-checked-t5 {
  background-color: #225544;
  margin: 4px;
}

.ant-switch-t1 {
  background-color: #ffbb44;
  padding: 2px;
  border-radius: 4px 4px 0px 0px;
}
.ant-switch-t2 {
  background-color: #3358ff;
  padding: 2px;
}
.ant-switch-t3 {
  background-color: #aa1100;
  padding: 2px;
}
.ant-switch-t4 {
  background-color: #11bb44;
  padding: 2px;
}
.ant-switch-t5 {
  background-color: #225544;
  padding: 2px;

  border-radius: 0px 0px 4px 4px;
}

.font-black {
  font-size: 8.5px !important;
  color: white;
}
</style>
