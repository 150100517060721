<template>
  <!-- Master Card -->
  <div class="card-main">
    <a-card class="card-game" style="height: 170px; cursor: pointer">
      <!-- <a-card class="card-credit header-solid h-full"> -->

      <div>
        <p
          style="
            background-image: linear-gradient(to top, #dba42e, #bf7d25);
            color: rgb(66, 26, 16);
            width: 100%;
            text-align: center;
            border-radius: 4px;
          "
        >
          โต๊ะหมายเลข {{ data.tableId }}
        </p>
        <div class="text-center" v-if="title === 'BACCARAT'">
          <img src="images/b_card.svg" width="60px" />
        </div>
        <div class="text-center" v-if="title === 'ROULETTE'">
          <img src="images/b_roulette.svg" width="60px" />
        </div>
        <div class="text-center" v-if="title === 'DRAGON_TIGER'">
          <img src="images/b_tiger.svg" width="60px" />
        </div>

        <!-- <div class="card-footer">
        <div class="card-footer-col col-logo ml-auto">
          <img src="images/logos/mastercard-logo.png" />
        </div>
      </div> -->
      </div>
      <Meter :winPercent="data.winRate"></Meter>
    </a-card>
    <!-- <div class="status center">
      <a-badge
        class="mb-0"
        style="margin-top: 5px"
        :status="data.tag"
        :text="data.status"
      />
      โต๊ะหมายเลข {{ data.tableId }}
    </div> -->
  </div>
  <!-- / Master Card -->
</template>

<script>
import Balance from "@/views/components/wallet/Balance.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Meter from "./Meter.vue";

export default {
  props: {
    title: {
      type: String,
    },

    data: {
      type: Object,
    },
  },
  components: {
    Balance,
    PulseLoader,
    Meter,
  },
  computed: {
    getUsername: function () {
      return JSON.parse(localStorage.getItem("userInfo")).displayName;
    },
    // displayTime: function () {
    //   return setInterval(this.refreshTime, 1000);
    // },
  },
  created() {
    setInterval(this.getNow, 1000);
    if (this.data.gameType === "BACCARAT") {
      this.image = "url('images/1.png')";
    } else if (this.data.gameType === "ROULETTE") {
      this.image = "url('images/2.png')";
    } else {
      this.image = "url('images/3.png')";
    }
  },
  data() {
    return { timestamp: "loading...", image: "" };
  },
  methods: {},
};
</script>
<style lang="scss">
.card-game {
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 8px 8px 8px 8px !important;
  background: rgb(66, 26, 16);
  background: linear-gradient(
    0deg,
    rgba(66, 26, 16, 1) 0%,
    rgba(120, 56, 21, 1) 64%,
    rgba(200, 110, 45, 1) 100%
  );
}

.card-main {
  transition: all 0.3s ease;
}

.card-main:hover {
  transform: translateY(-20px);
}

.status {
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border-radius: 0px 0px 8px 8px;
  padding: 4px;
  span {
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
}
</style>
